@import '~stylesheets/abstracts';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .mainContent {
    max-width: 1200px;
    width: 100%;

    padding-left: 20px;
    padding-right: 20px;

    @include media('>small_plus') {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
