@import '~stylesheets/abstracts';

.navItemsWrapper {
  .active {
    color: #ff4d5a;
    font-weight: bold !important;
  }

  @include media('<medium') {
    ul {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      align-content: center;

      cursor: pointer;

      .line {
        margin-bottom: 5px;
        width: 32px;
        height: 2px;
        background: white;
        border-radius: 10px;
        position: relative;

        span {
          display: none;

          position: absolute;
          right: 60px;
          margin-top: -11px;

          font-family: 'Kanit', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-transform: capitalize;
        }
      }

      .active {
        background: #ff4d5a;
        width: 50px;
        height: 3px;
        color: white;

        span {
          display: block;
        }
      }
    }
  }

  @include media('>=medium') {
    ul {
      display: flex;

      align-items: center;

      li {
        margin-left: 22px;
      }
    }

    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    justify-content: flex-end;

    img {
      display: none !important;
    }
  }
  img {
    display: block;
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    color: white;

    li {
      .navItem {
        font-family: Kanit;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;

        cursor: pointer;
        &:hover {
          color: #ff4d5a;
          transform: scale(1.01);
        }
      }
    }
  }
}

.modalItemsWrapper {
  display: flex;

  align-items: center;
  ul {
    width: 100%;
    padding-left: 0;
  }

  .modalItem {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;

    font-family: Kanit;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 51px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  .modalItemActive {
    background: #ff4d5a;
  }
}
