body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.homeSlider.flickity-enabled{
  width: 280px;
}

.homeSlider.flickity-viewport{
  width: 280px;

}

.homeSlider {
  .flickity-page-dots .dot{
    background: rgba(255, 255, 255, 0.15);
  }
}


.flickity-enabled {
  position: relative;
  width: 100%;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  display: none;
}

.flickity-carousel-cell {
 
}
/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 6px;
  background: rgb(212, 212, 212);
  border-radius: 50%;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  background: #FF4D5A;
  opacity: 1;
}