@import '~stylesheets/abstracts';

.headerWrapper {
  font-family: 'Kanit';

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  height: 53px;

  display: flex;
  justify-content: center;
  z-index: 10000;

  @include media('<medium') {
    background: none;
  }

  .hidden {
    height: 0 !important;
  }

  /* The Overlay (background) */
  .menu {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100vh;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

    display: flex;
    justify-content: center;

    /* Position the content inside the overlay */
    div {
      position: relative;
      top: 10%; /* 25% from the top */
      width: 100%; /* 100% width */
      text-align: center; /* Centered text/links */

      @include media('<small') {
        a {
          font-size: 20px;
        }
      }

      a {
        padding: 8px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block; /* Display block instead of inline */
        transition: 0.3s;
        &:hover {
          color: #f1f1f1;
        }
      }
    }
    span {
      position: absolute;
      bottom: 100px;
      // right: 50%;
      font-size: 60px;
      color: white;
      font-size: 40px;
      cursor: pointer;
    }
  }

  .headerContentWrapper {
    max-width: 1200px;

    width: 100%;
    display: flex;

    position: relative;

    img {
      cursor: pointer;
    }

    .projectNavigation {
      color: white;

      display: flex;
      align-items: center;
      text-align: right;
      padding-right: 30px;
    }

    @include media('>=medium') {
      justify-content: flex-end;
    }

    .mobileNavWrapper {
      background: linear-gradient(180deg, #051f3a 0%, #031321 100%);

      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      display: flex;

      justify-content: space-between;

      .projectNavigation {
        padding-right: 0;
      }
    }

    .desktopNavWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 35px;
      width: 100%;
    }

    .hideBackground {
      background: none;

      justify-content: flex-end;

      display: flex;

      right: 0;
      top: 0;
      padding: 24px;
      padding-right: 20px;
      padding-top: 40px;
    }

    .navbarWrapper {
      width: 100px;
    }

    .modal {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: black;

      .modalVisible {
        display: block;
      }
    }
  }
}
