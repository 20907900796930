@import '~stylesheets/abstracts';

.landingWrapper {
  //   height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;

  .contentWrapper {
    width: 100%;
    height: 100%;
    font-weight: 400;

    position: relative;
  }
}

//////////LOADER/////
.loaderWrapper {
  background: #031621;
  background: linear-gradient(75deg, rgb(1, 4, 9) 0%, rgb(4, 32, 56) 50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #ff4d5a;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  font-variant: all-small-caps;
  font-family: Roboto;
  font-weight: bold;
  color: white;
}
