.menu {
  gap: 7px;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
}
