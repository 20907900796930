@import '~stylesheets/abstracts';

.homeWrapper {
  background-size: cover;
  background-position: center;
  height: 100vh;
  min-height: 600px;


  @include media('>=medium') {
    min-height: 900px;
  }


  width: 100%;
  position: relative;

  .homeContentWrapper {
    padding-top: 12px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media('>=medium') {
      padding-top: 151px;
    }

    @include media('>large') {
      padding-top: 232px;
    }

    .logo {
      height: auto;
      width: 280px;

      @include media('>=medium') {
        width: 515px;
      }
      @include media('>=large') {
        width: 654px;
      }
    }

    .illustrationsWrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .illustrations {
        height: auto;

        display: none;

        @include media('>=medium') {
          width: 705px;
          display: block;
        }

        @include media('>=large') {
          width: 824px;
          display: block;
        }
      }

      .sliderWrapper {
        margin-top: 100px;

        width: 100%;
        display: flex;
        justify-content: center;

        .sliderItem {

        }
      }
    }
  }
}
