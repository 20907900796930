@import '~stylesheets/abstracts';

.projectWrapper {
  background-size: cover;
  position: relative;
  background-position: center;
  height: 100vh;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .projectHeader {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    text-align: right;

    font-style: 'Kanit';

    z-index: 100000;

    img {
      cursor: pointer;
    }
    span {
      color: white;

      display: flex;
      align-items: center;
    }

    max-width: 1200px;
    width: 100%;

    padding-left: 20px;
    padding-right: 20px;

    @include media('>small_plus') {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .carouselWrapper {
    width: 100%;
    height: 75vh;
    max-height: 50vw;

    position: relative;
    .arrowLeft {
      position: absolute;
      top: calc(50% - 15px);
      left: 30px;
      z-index: 1000;
      height: 30px;
      width: 30px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: #ff4d5a;
        transform: scale(1.05);
      }

      img {
        width: 18px;
        height: 18px;

        @include media('>medium') {
          width: 24px;
          height: 24px;
        }
      }

      @include media('>medium') {
        left: 50px;
        height: 100px;
        width: 100px;
        top: calc(50% - 50px);
      }
    }

    .arrowRight {
      position: absolute;
      top: calc(50% - 15px);
      right: 30px;
      z-index: 1000;

      background: black;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background: rgba(0, 0, 0, 0.3);

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: #ff4d5a;
        transform: scale(1.05);
      }

      img {
        width: 18px;
        height: 18px;
        transform: rotate(180deg);

        @include media('>medium') {
          width: 24px;
          height: 24px;
        }
      }

      @include media('>medium') {
        right: 50px;
        height: 100px;
        width: 100px;
        top: calc(50% - 50px);
      }
    }
  }
}

.footerWrapper {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  // position: relative;
  display: flex;
  justify-content: center;

  @include media('height>small_plus', '<=medium') {
    bottom: 80px;
  }

  .projectDetails {
    font-family: 'Kanit';
    font-weight: 300;
    color: white;
    margin-top: 10px;
    font-size: 16px;

    @include media('>medium') {
      font-size: 18px;
    }


  }

  .scroll {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    @include media('height>small_plus', '<=medium') {
      bottom: 100;
    }
    div {
      //   padding-top: 60px;
      span {
        // @include media('>large') {
        //   left: 50%;
        //   top: 62px;
        //   width: 26px;
        //   height: 38px;
        // }

        top: 34px;
        left: calc(50% + 5px);
        width: 20px;
        height: 32px;

        position: absolute;

        margin-left: -15px;
        border: 2px solid white;
        border-radius: 50px;
        box-sizing: border-box;

        &::before {
          position: absolute;
          top: 10px;
          left: 50%;
          content: '';
          width: 4px;
          height: 4px;
          margin-left: -2px;
          background-color: white;
          border-radius: 100%;
          -webkit-animation: sdb10 2s infinite;
          animation: sdb10 2s infinite;
          box-sizing: border-box;
        }
      }
    }
  }
  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 18px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 18px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

.contentWrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  h1 {
    color: #444649;
    margin-bottom: 30px;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 156.69%;
    /* or 25px */

    color: #444649;
  }

  b {
    font-size: 18px;
    line-height: 156.69%;
  }
}
