@import '~stylesheets/abstracts';

.contactWrapper {
  background-position: center;
  background-size: cover;

  .contactContentWrapper {
    // height: 850px;
    // padding-top: 182px;
    // padding-bottom: 30px;

    h1 {
      font-family: Kanit;
      font-style: normal;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 16px;
      margin-top: 58px;

      font-size: 36px;
      line-height: 54px;

      @include media('>=medium') {
        font-size: 72px;
        line-height: 108px;
        margin-top: 162px;
      }
    }

    h2 {
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;

      color: #ffffff;
      margin-bottom: 37px;

      font-size: 16px;
      line-height: 24px;

      @include media('>=medium') {
        font-size: 26px;
        line-height: 39px;
      }
    }
    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .cvDownloadButton {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 194px;

        background: #ff4d5a;
        border-radius: 10px;

        padding: 5px 10px;

        span {
          font-family: Kanit;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          margin-right: 12px;
          color: #ffffff;
        }
        margin-right: 24px;
        margin-bottom: 10px;
      }

      .emailButton {
        cursor: pointer;

        a {
          text-decoration: none;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 194px;

        border: 1px solid #ff4d5a;
        border-radius: 10px;

        padding: 5px 10px;

        span {
          font-family: Kanit;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */
          margin-left: 10px;
          color: #ffffff;
        }
        margin-bottom: 10px;
      }
    }

    .footerWrapper {
      margin-top: 75px;
      margin-bottom: 50px;

      @include media('>=medium') {
        margin-top: 234px;
        margin-bottom: 30px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-family: Kanit;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */
        text-align: center;

        color: #ffffff;
      }
      .footerIcons {
        padding-top: 15px;
        width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 35px;
      }
    }
  }
}
